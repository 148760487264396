.spinner-container {
  display: flex;
  justify-content: center;
  margin-top: 20px; 
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333; 
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite; 
}

@keyframes spin {
  to {
    transform: rotate(360deg); 
  }
}