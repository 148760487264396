@import "colors";

ul {
  list-style-type: none; 
  padding: 0; 
}

ul li {
  text-align: left; 
}

.error {
  color: #ff0000;
  font-size: 14px;
  margin-bottom: 0px;
}

.payment {
  font-size: larger;
  padding-top: 1vh;
  padding-bottom: 2vh;
}

.discountcode input[type="text"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  width: 160px;
  text-align: left;
}

.discountcode button {
  padding: 8px 16px;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 4px;
  width: 170px;
  cursor: pointer;
  margin-right: 8px;
}

input[type="text"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  width: 340px;
  text-align: left;
  margin-bottom: 8px;
}

.list-country {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  width: 340px;
  margin-bottom: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white !important;
  text-align: left !important;
  color: black !important;
}

.list-country option {
  text-align: left !important;
}

.information {
  margin-top: 16px;
  padding: 8px 16px;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  width: 180px;
}

.information:hover,
.discountcode button:hover {
  background-color: rgb(50, 50, 50);
  box-shadow: 0 0 10px rgba(100, 100, 100, 1); 
}

@media screen and (max-width: 600px) {

}

.payment-methods input[type="radio"] + label {
  margin-right: 5px; 
  margin-left: 5px; 
}

.disabled-label {
  color: #ccc;
  cursor: not-allowed;
}

.disabled-label:hover + .disabled-tooltip {
  display: inline;
}

.center-content {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 10px !important;
  text-align: center; 
  background-color: #DFE2EB;
  padding-left: 10px;
  padding-bottom: 25px;
}

.checkout {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
}

.margin-less{
  margin-left: -2rem;
}