* {
  margin: 0%;
  padding: 0%;
  font-family: "Ubuntu", sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

.compact-text {
  font-size: 14px; 
}


@import "colors";


