@import "colors";
.main-container {
  padding-top: 1rem;
  display: flex;
  flex-direction: column; 
  align-items: center; 
}

.header-container {
  margin-top: 0px;
  text-align: left;
  max-width: 1000px;
  width: auto !important;
  margin-bottom: 1rem;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
  background-color: #DFE2EB;
  border-bottom: solid 3px whitesmoke;
  border-right: solid 3px whitesmoke;
  border-radius: 10px;
}

.header-container h3 {
  font-size: 16px;
}

.selection-guide {
  max-width: 1000px;
  width: auto;
  background-color: #DFE2EB;
  border-radius: 10px;
  background-size: cover; 
  background-position: center; 
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-bottom: solid 3px whitesmoke;
  border-right: solid 3px whitesmoke;
}

.container-section-products{
  margin-left: 1rem;
  margin-right: 1rem;
}

.h1-center{
  text-align: center;
}

.navigation-link {
  color: black;
  text-decoration: none;
}

.product-display {
  display: flex;
  max-width: 1010px;
  justify-content: left; 
  align-items: left; 
  border-radius: 10px;
  margin-bottom: 40px;
}
