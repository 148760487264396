.quantity{
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px; 
}

.increaseQuantity-button {
  background-color: grey !important; 
}
