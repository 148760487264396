.article-container {
  margin-top: 20px;
  text-align: left;
  max-width: 1000px;
  padding: 20px;
  background-color: #DFE2EB;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto; 
  border-bottom: solid 3px whitesmoke;
  border-right: solid 3px whitesmoke;
}

.review-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0 auto;
  border-radius: 8px;  
}

.review-form textarea
{
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 200px;
  width: 200px;
  text-align: left;
  color: black !important;
  background-color: white !important;
}

.review-form select, .review-form input
{
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 30px;
  width: 200px;
  text-align: left;
  color: black !important;
  background-color: white !important
}

.review-form label {
  padding-right: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 16px;
}

.black-link {
  color: black !important;
}

.nav-link{
  display: inline !important;
  text-decoration: none; 
  color: black !important;
}

.nav-center{
  padding-top: 1rem;
  text-align: center;
}

@media screen and (max-width: 1100px) {
  .article-container {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
}