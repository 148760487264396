@import "colors";

nav {
  height: 5rem;
  padding-right: 1rem !important;
  display: flex;
  box-shadow: none !important;
  background-color: whitesmoke;
  position: sticky !important;
  top: 0 !important;
  z-index: 1000;
  border-bottom: solid 1px #DFE2EB;

  > div {
    display: flex;
    align-items: center;
    flex-grow: 1; 

    > a {
      margin: 0 2rem;
      color: $dark;
      transition: color 0.3s;

      &:hover {
        color: $lightdark;
      }

    }
  }
}

.cart-icon {
  padding-right: 20px;
}

.logo-desktop {
  font-size: 40px;
}

.logo-desktop strong {
  color:rgb(0, 0, 139) !important;
}

.logo-mobile {
  font-size: 40px;
  display: none;
  
}

.logo-mobile strong {
  color:rgb(0, 0, 139) !important;
}

@media screen and (max-width: 600px) {
  nav {
    padding: 1rem;
    > div > a {
      margin: 0 0.5rem;
    }
  }
}

@media screen and (max-width: 765px) {
  .header-text {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .logo-desktop {
    display: none;
  }
  .logo-mobile {
    display: block;
  }
}
