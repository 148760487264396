@import "colors";

footer {
  
  background-color: whitesmoke;
  color: black;
  border-top: solid 1px #DFE2EB;

  h2 {
    color: $red;
    font-family: fantasy;
    font-weight: 400;
    font-size: 2rem;
  }

  p,
  strong {
    display: grid;
    grid-template-columns: 3fr; 
  }

  .main-container{
    display: flex;
    flex-direction: row;
    align-items: normal;
  }
  
  .image-container {
    display: flex;
    justify-content:center;
    padding-bottom: 1rem;
  }

  .image-container img {
    height: 50px; 
    padding-left: 5px;
    padding-right: 5px;
  }

  .info-container {
    width: 50%;
    display: grid;
    text-align: left; 
    padding-left: 1rem;
  }

  .extra-container{
    width: 50%;
    display: grid;
    text-align: right; 
    padding-right: 1rem;
  }

  .twitter-icon {
    font-size: 24px;
    color: black; 
  }
}

@media screen and (max-width: 750px) {
  .info-container {
    padding-bottom: 1rem;
  } 
}

