.storeItem-top{
  height: 50px;
  border-radius: 20px 20px 0 0;
  background-color: #DFE2EB; 
  display: flex;
  align-items: center;
  padding: 2rem;
  color: white;
  border: solid 2px white;
}

.h-100 {
  border-radius: 1.5rem !important;
}

.reviews{
  background-color: white;
  border: solid 3px whitesmoke;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
}

.fixed-height {
  height: 600px; 
  overflow-y: auto;
}


@media (max-width: 1200px) {
  .fixed-height {
    height: 600px; 
  }
}

@media (max-width: 1100px) {
  .fixed-height {
    height: 600px; 
  }
}

@media (max-width: 992px) {
  .fixed-height {
    height: 570px; 
  }
}

@media (max-width: 800px) {
  .fixed-height {
    height: 600px; 
  }
}

@media (max-width: 768px) {
  .fixed-height {
    height: 570px;
  }
}

@media (max-width: 530px) {
  .fixed-height {
    height: 570px;
  }
}

@media (max-width: 440px) {
  .fixed-height {
    height: 550px;
  }
}

@media (max-width: 400px) {
  .fixed-height {
    height: 550px;
  }
}