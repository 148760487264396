@import "colors";



.page-not-found {
    
    text-align: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 1rem;

    a {
        color: black;
        text-decoration: none; 
    }
}

.page-not-found-main {
    
    text-align: center;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    padding: 1rem;
    padding-bottom: 8rem;
}

.page-not-found-child{
    background-color: white;
    padding: 1rem;
    border-radius: 1rem;
    margin: 2rem;
        
    >img {
        border-radius: 10px;
        width: 300px;
        max-width: 300px;
        height: auto;
    }
}
    

@media (max-width: 530px) {
    .page-not-found-main {
        background-color: white;
    }
    .page-not-found-child{
        background-color: white;
    }
}